<template>
<div>
    <el-collapse>
        <el-collapse-item>
            <template slot="title">
                <i class="header-icon el-icon-s-operation"></i>筛选
                <span class="totalwrp">总条数:{{total}}</span>
            </template>
            <div>
                <el-row :gutter="8">
                    <el-col :span="6" class="searchwrp">
                        <el-button round @click="addServe">新增</el-button>
                    </el-col>
                    <el-col :span="6" class="searchwrp">
                        <label for="">搜索 </label>
                        <el-input type="text" v-model="keyword" clearable></el-input>
                    </el-col>

                    <el-col :span="4">
                        <el-button type="primary" round @click="searchKeyWord">筛选</el-button>
                    </el-col>
                </el-row>
            </div>
        </el-collapse-item>
    </el-collapse>

    <el-table :data="orders" stripe tooltip-effect="dark" fit style="width: 100%" row-class-name="rows">
        <el-table-column label="ID" width="90" prop="Orders_Number">
            <template #default="scope">{{ scope.row.MerchantPublish_ID }}</template>
        </el-table-column>
        <el-table-column label="服务名称" width="270" show-overflow-tooltip>
            <template #default="scope">{{ scope.row.MerchantPublish_Name }}</template>
        </el-table-column>
        <el-table-column label="服务信息内容">
            <template #default="scope">{{ scope.row.MerchantPublish_Content }}</template>
        </el-table-column>
        <el-table-column label="发布时间">
            <template #default="scope">{{ scope.row.MerchantPublish_Create }}</template>
        </el-table-column>
        <el-table-column label="操作" width="120">
            <template #default="scope">
                <el-button type="primary" @click="beforeEdit(scope.$index)">修改</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog title="添加服务信息" v-model="addVisible" :modal="false">
        <el-row class="edit-info">
            <el-col :span="24">
                <label>名称</label>
                <el-input type="text" v-model="MerchantPublish_Name"></el-input>
            </el-col>
            <el-col :span="24">
                <label>内容</label>
                <el-input type="textarea" autosize v-model="MerchantPublish_Content"></el-input>
            </el-col>
        </el-row>
        <div slot="footer" class="dialog-footer">
            <el-button @click="addVisible = false">取 消</el-button>
            <el-button type="primary" @click="sureToAdd">确定</el-button>
        </div>
    </el-dialog>
    <el-dialog title="修改服务信息" v-model="editDetailVisible" :modal="false">
        <el-row class="edit-info">
            <el-col :span="24">
                <label>名称</label>
                <el-input type="text" v-model="MerchantPublish_Name"></el-input>
            </el-col>
            <el-col :span="24">
                <label>内容</label>
                <el-input type="textarea" autosize v-model="MerchantPublish_Content"></el-input>
            </el-col>
        </el-row>
        <div slot="footer" class="dialog-footer">
            <el-button @click="editDetailVisible = false">取 消</el-button>
            <el-button type="primary" @click="sureToEdit">确定修改</el-button>
        </div>
    </el-dialog>

    <el-pagination background layout="prev, pager, next" :total="total" @current-change="setPageIndx">
    </el-pagination>
</div>
</template>

<script>
let app = null
export default {
    data() {
        return {
            domin: "",
            MerchantPublish_Name: "",
            MerchantPublish_Content: "",
            current: 0,
            verify: "",
            starttime: "",
            endtime: "",
            activeType: "",
            activeArr: [],
            detailsList: [],
            addVisible: false,
            detailFormVisible: false,
            editDetailVisible: false,
            total: 0,
            Orders_ID: "",
            status: "",
            keyword: "",
            dateGap: "",
            orderIndx: 0,
            currentIndx: 0,
            remarkForm: {
                remark: "",
            },
            remarkFormVisible: false,
            orders: [],
            pageindex: 1,
        };
    },

    created() {
        app = this.$.appContext.app
        this.domin = app.domin
        console.log(app.domin)
    },
    mounted() {
        this.pullList();
        this.pullActive()
    },
    methods: {
        sureToAdd() {

            if (this.MerchantPublish_Name == "" || this.MerchantPublish_Content == "") {
                this.$message("请填写完整服务信息的名称和内容！")
                return false
            }
            let newModel = {
                MerchantPublish_Name: this.MerchantPublish_Name,
                MerchantPublish_Content: this.MerchantPublish_Content
            }
            app.wpost({
                url: "/AdminAjax/MerchantPublish.ashx?type=insertmerchant",
                data: {
                    model: JSON.stringify(newModel),
                },
                succ: (res) => {
                    // console.log("修改详情res:", res);
                    this.$message("新增成功！");
                    this.addVisible = false;
                    this.pullList();
                }
            });
        },
        addServe() {
            this.addVisible = true
            this.MerchantPublish_Name = ""
            this.MerchantPublish_Content = ""
        },
        beforeEdit(indx) {
            console.log("序号：", indx)
            this.current = indx

            let newModel = Object.assign(this.orders[this.current])

            this.MerchantPublish_Name = newModel.MerchantPublish_Name
            this.MerchantPublish_Content = newModel.MerchantPublish_Content

            this.editDetailVisible = true

        },
        sureToEdit() {
            if (this.MerchantPublish_Name == "" || this.MerchantPublish_Content == "") {
                this.$message("请填写完整服务信息的名称和内容！")
                return false
            }

            let newModel = Object.assign(this.orders[this.current])
            newModel.MerchantPublish_Name = this.MerchantPublish_Name
            newModel.MerchantPublish_Content = this.MerchantPublish_Content

            console.log("new Model", newModel)

            app.wpost({
                url: "/AdminAjax/MerchantPublish.ashx?type=update",
                data: {
                    model: JSON.stringify(newModel),
                },
                succ: (res) => {
                    this.$message("修改成功！");
                    this.editDetailVisible = false;
                    this.pullList();
                }
            });

        },
        searchKeyWord() {
            this.pageindex = 1;
            this.pullList();
        },

        editDetail(index) {
            this.editDetailVisible = true;
            console.log(this.orders[index]);
            this.orderIndx = index;
            let newModel = this.orders[index];
            this.Orders_Number = newModel.Orders_Number;
            this.Orders_Receiver = newModel.Orders_Receiver;
            this.Orders_Phone = newModel.Orders_Phone;
            this.Orders_Address = newModel.Orders_Address;
            this.Orders_Freight = newModel.Orders_Freight;
            this.Orders_Total = newModel.Orders_Total;
            this.Orders_Created = newModel.Orders_Created;
            this.Orders_DeliveryTime = newModel.Orders_DeliveryTime;
            this.Orders_ReceiveTime = newModel.Orders_ReceiveTime;
            this.Orders_Remark = newModel.Orders_Remark;
        },
        sureToEditDetail() {
            let newModel = this.orders[this.orderIndx];
            newModel.Orders_Number = this.Orders_Number;
            newModel.Orders_Receiver = this.Orders_Receiver;
            newModel.Orders_Phone = this.Orders_Phone;
            newModel.Orders_Address = this.Orders_Address;
            newModel.Orders_Freight = this.Orders_Freight;
            newModel.Orders_Total = this.Orders_Total;
            newModel.Orders_Created = this.Orders_Created;
            newModel.Orders_DeliveryTime = this.Orders_DeliveryTime;
            newModel.Orders_ReceiveTime = this.Orders_ReceiveTime;
            newModel.Orders_Remark = this.Orders_Remark;
            app.wpost({
                url: "/AdminAjax/MerchantOrders.ashx?type=update",
                data: {
                    model: JSON.stringify(newModel),
                },
                succ: (res) => {
                    // console.log("修改详情res:", res);
                    this.$message("修改成功！");
                    this.editDetailVisible = false;
                    this.pullList();
                }
            });
        },
        deletOrder(item) {
            app.wpost({
                url: "/AdminAjax/MerchantOrders.ashx?type=delete",
                data: {
                    model: JSON.stringify(item),
                },
                succ: (res) => {
                    this.$message("删除成功！");
                    this.pullList();
                }
            });
        },
        sendStatus(statuNum, id) {
            app.wpost({
                url: "/AdminAjax/InvoiceReceive.ashx?type=updatestatus",
                data: {
                    id: id,
                    status: statuNum,
                },
                succ: (res) => {
                    this.$message("设置成功！");
                    this.pullList();
                }
            });
        },

        switchStatus(num) {
            let statusText = "";
            // 0：全部，2：待发货，3：待收货，4：待评价，5：待分享，6：交易完成
            switch (num) {
                case 0:
                    statusText = "全部";
                    break;

                case 2:
                    statusText = "待发货";
                    break;
                case 3:
                    statusText = "待收货";
                    break;
                case 4:
                    statusText = "待评价";
                    break;
                case 5:
                    statusText = "待分享";
                    break;
                case 6:
                    statusText = "交易完成";
                    break;
            }
            return statusText;
        },
        sendDeliver() {

            if (this.deliverID == "" || this.deliverCompy == "") {
                this.$message("请填写完整快递信息内容");
                return false;
            }
            app.wpost({
                url: "/AdminAjax/MerchantOrders.ashx?type=delivery",
                data: {
                    id: this.Orders_ID,
                    name: this.deliverCompy,
                    number: this.deliverID,
                },
                succ: (res) => {
                    // console.log("res:", res);
                    this.$message("设置发货成功");
                    this.detailFormVisible = false;
                }
            });
        },
        pullDetailsList(item) {
            this.deliverID = item.Orders_LogisticsNumber
            this.deliverCompy = item.Orders_LogisticsName
            this.Orders_ID = item.Orders_ID;
            app.wpost({
                url: "/AdminAjax/MerchantOrders.ashx?type=selectdetail",
                data: {
                    id: item.Orders_ID,
                },
                succ: (res) => {
                    // console.log("res:", res);
                    this.detailsList = res.list;
                    this.detailFormVisible = true;
                }
            });
        },
        pullList() {
            let that = this;
            app.wpost({
                url: "/AdminAjax/MerchantPublish.ashx?type=selectmerchant",
                data: {
                    keyword: this.keyword,
                    pageindex: this.pageindex,
                    pagesize: 10,
                },
                succ: (res) => {
                    // console.log("res:", res);
                    this.orders = res.list;
                    this.total = res.count;
                }
            });
        },

        pullActive() {

            app.wpost({
                url: "/AdminAjax/MerchantProduct.ashx?type=select",
                data: {
                    pageindex: 1,
                    pagesize: 999
                },
                succ: (res) => {
                    // console.log("res:", res);
                    this.activeArr = res.list;
                }
            });

        },
        setPageIndx(pgindx) {
            // console.log(e);
            this.pageindex = pgindx;
            this.pullList();
        },
    },
};
</script>

<style>
.rows td:nth-child(10) {
    padding: 0 !important;
}
</style><style scoped>
.searchwrp {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.searchwrp label {
    display: block;
    width: 66px;
}

.imgsArr {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.imgsArr img {
    height: 40px;
    margin-right: 6px;
}

.deliver-info .el-col {
    padding: 22px 0 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.deliver-info .el-col label {
    display: block;
    width: 100px;
}

.edit-info label {
    display: block;
    padding: 10px 0;
}

.edit-info .el-input,
.edit-info .el-select,
.edit-info .el-date-picker {
    width: 98%;
}

.el-button {
    margin: 0 6px;
}

.el-collapse-item .el-row {
    padding: 6px 12px;
}

.search-wrp .el-input {
    width: 60%;
    margin-right: 10px;
}

.el-pagination {
    margin: 16px auto;
    text-align: center;
}

.dbtns {
    padding-top: 20px;
    text-align: right;
}
</style>
